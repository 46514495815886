<template>
	<div class="py-6 flex flex-grow justify-center w-full h-full">
		<div v-if="loading && !loaded" class="w-full h-full">
			<div class="flex w-full h-full px-4 py-6 justify-center">
				<Loading />
			</div>
		</div>
		<div v-else-if="loaded && schema" class="bg-white shadow sm:rounded-lg w-full mx-6 flex flex-col justify-between h-full overflow-hidden">
			<div class="px-4 py-1.5 sm:px-6 flex flex-row justify-between">
				<div class="flex flex-col justify-center items-center">
					<h2 class="text-2xl leading-6 font-semibold text-black">{{ title ? title + ' - ' : '' }}{{ formRequest?.form?.name }}</h2>
				</div>
				<div class="flex flex-col">
					<div class="flex flex-row justify-end items-center">Users here now!</div>
					<div class="flex flex-row justify-end items-center space-x-1">
						<UserAvatar v-for="user in usersHereNow" :key="user.id" :first-name="user.first_name" :last-name="user.last_name" :size="6" classes="text-xs"></UserAvatar>
					</div>
				</div>
			</div>
			<div class="border-t border-gray-200 px-4 pt-3 pb-5 sm:px-6 h-full overflow-y-auto">
				<FormKit id="form_request" :key="formRequestId + '_' + formDisabled" v-model="formData" :no-empty-params="true" :disabled="formDisabled" type="form" :actions="false" @submit="submitFormRequest">
					<div class="grid grid-cols-12 gap-x-2 gap-y-4 my-4">
						<FormKitSchema :schema="schema" :data="formData" />
					</div>
				</FormKit>
				<DebugSection>
					<hr />
					<h3 class="font-bold">Form data</h3>
					<pre>{{ cleanFormData(formData) }}</pre>
				</DebugSection>
			</div>
			<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row sm:space-x-4 sm:justify-end sm:px-8 sm:rounded-b-lg sticky bottom-0">
				<Button :disabled="formDisabled || formSubmitting" :loading="formSaving" :label="saveButtonLabel" color="white" @click="triggerFormRequestSave(formData)" />
				<Button :disabled="formDisabled || formSaving" :loading="formSubmitting" :label="submitButtonLabel" color="primary" @click="triggerFormRequestSubmit" />
			</div>
		</div>
		<div v-else class="w-full h-full">
			<div class="flex w-full h-full px-4 py-6 justify-center">
				<div class="flex flex-row items-center justify-center space-x-2">
					<div class="font-semibold text-4xl px-5 anim">An error occurred...</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, defineProps, onBeforeUnmount, onMounted, ref, watch, withDefaults } from 'vue';
	import { GET_FORM_REQUEST_BY_ID } from '@modules/form/graphql/FormQueries';
	import { FormRequest } from '@/types/graphql';
	import { FormKitSchemaNode } from '@formkit/core';
	import { submitForm } from '@formkit/vue';
	import { UnstructuredFormOutput } from '@/types/form';
	import { FormRequestStatus } from '@modules/form/utils/constants';
	import { SUBMIT_FORM_REQUEST, UPDATE_FORM_REQUEST } from '@modules/form/graphql/FormMutations';
	import useNotify from '@utils/useNotify';
	import { AlertIcons } from '@/types/dialog';
	import PusherService from '@/services/usePusher';
	import { Channel, Members, PresenceChannel } from 'pusher-js';
	import { safeUnpack } from '@utils/helpers';
	import useGraphQL from '@utils/useGraphQL';
	import Loading from '@components/Loading.vue';
	import { useAuthStore } from '@modules/auth/store';
	import UserAvatar from '@modules/user/components/UserAvatar.vue';
	import DebugSection from '@components/DebugSection.vue';
	import { cleanFormData } from '../utils/helpers';
	import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
	import useDialog from '@utils/useDialog';
	import clone from '@utils/useClone';
	import { watchDebounced } from '@vueuse/core/index';
	import Button from '@components/Button.vue';

	interface PresenceChannelUsers {
		id: string;
		info: PresenceChannelUser;
	}

	interface PresenceChannelUser {
		id: string;
		responder_id: string;
		first_name: string;
		last_name: string;
		full_name: string;
		middle_name: string;
		joined_at: string;
	}

	interface Props {
		formRequestId: string;
		readOnly?: boolean;
		title?: string;
		referenceName?: string;
		submitButtonLabel?: string;
		saveButtonLabel?: string;
	}

	const props = withDefaults(defineProps<Props>(), {
		readOnly: false,
		title: undefined,
		referenceName: 'Form',
		submitButtonLabel: 'Submit',
		saveButtonLabel: 'Save',
	});

	const loading = ref(false);
	const loaded = ref(false);
	const formSubmitted = ref(false);
	const schema = ref();
	const formRequestChannel = ref<Channel | PresenceChannel>();
	const formData = ref<UnstructuredFormOutput>({});
	const formRequest = ref<FormRequest>();
	const authStore = useAuthStore();
	const usersHereNow = ref<PresenceChannelUser[]>([]);
	const originalFormData = ref<undefined | string>(undefined);
	const formSubmitting = ref(false);
	const formSaving = ref(false);

	const getFormRequestById = async (formRequestId: string) => {
		loading.value = true;
		const graphQlResult = await useGraphQL.query(
			GET_FORM_REQUEST_BY_ID,
			{
				form_request_id: formRequestId,
			},
			{
				fetchPolicy: 'no-cache',
			},
		);

		if (graphQlResult.getFormRequestById) {
			if (!schema.value) {
				schema.value = safeUnpack<FormKitSchemaNode[]>(graphQlResult.getFormRequestById.form_version?.schema, []);
			}

			if (JSON.stringify(formData.value) !== JSON.stringify(Object.assign({}, formData.value, safeUnpack<UnstructuredFormOutput | undefined>(graphQlResult.getFormRequestById.data, undefined)))) {
				formData.value = Object.assign({}, formData.value, safeUnpack<UnstructuredFormOutput | undefined>(graphQlResult.getFormRequestById.data, undefined));
			}
			if (originalFormData.value === undefined) {
				originalFormData.value = JSON.stringify(cleanFormData(formData.value));
			}
			formRequest.value = graphQlResult.getFormRequestById;

			loaded.value = true;
		}

		loading.value = false;
	};

	const triggerFormRequestSave = async (formRequestData: UnstructuredFormOutput, manualUpdate = true) => {
		if (JSON.stringify(cleanFormData(formRequestData)) !== originalFormData.value) {
			formSaving.value = true;
			useGraphQL
				.mutate(UPDATE_FORM_REQUEST, {
					input: {
						id: props.formRequestId,
						data: JSON.stringify(cleanFormData(formRequestData)),
					},
				})
				.then((graphQlResult) => {
					if (graphQlResult?.updateFormRequest) {
						formRequest.value = graphQlResult.updateFormRequest;
						useNotify
							.icon({ icon: AlertIcons.Success })
							.title(props.referenceName + ' successfully saved')
							.fire();
						originalFormData.value = JSON.stringify(cleanFormData(formRequestData));
					}
				})
				.finally(() => {
					formSaving.value = false;
				});
		} else {
			if (manualUpdate) {
				useNotify.icon({ icon: AlertIcons.Info }).title('Nothing to update!').fire();
			}
		}
	};

	const submitFormRequest = async (formRequestData: UnstructuredFormOutput) => {
		formSubmitting.value = true;
		useGraphQL
			.mutate(SUBMIT_FORM_REQUEST, {
				input: {
					id: props.formRequestId,
					data: JSON.stringify(cleanFormData(formRequestData)),
				},
			})
			.then((graphQlResult) => {
				if (graphQlResult?.submitFormRequest) {
					formRequest.value = graphQlResult.submitFormRequest;
					useNotify
						.icon({ icon: AlertIcons.Success })
						.title(props.referenceName + ' successfully submitted')
						.fire();
					originalFormData.value = JSON.stringify(cleanFormData(formData.value));
				}
			})
			.finally(() => {
				formSubmitting.value = false;
			});
	};

	watchDebounced(
		formData,
		(initiatorData: UnstructuredFormOutput) => {
			triggerFormRequestSave(initiatorData, false);
		},
		{ debounce: 30000 },
	);

	const formDisabled = computed(() => {
		if (props.readOnly) {
			return true;
		}
		if (formSubmitted.value === true) {
			return true;
		}

		return !!(formRequest.value && [FormRequestStatus.AWAITING_ACTIVATION, FormRequestStatus.CLOSED, FormRequestStatus.SUBMITTED, FormRequestStatus.REVIEWING].includes(formRequest.value.status));
	});

	const triggerFormRequestSubmit = () => {
		submitForm('form_request');
	};

	function userJoinedChannel(userInfo: PresenceChannelUser) {
		if (!usersHereNow.value.find((o) => o.id === userInfo.id)) {
			usersHereNow.value.push(userInfo);
		}
	}

	function userLeftChannel(userInfo: PresenceChannelUser) {
		const userToRemoveIndex = usersHereNow.value.findIndex((o) => o.id === userInfo.id);
		if (userToRemoveIndex) {
			usersHereNow.value.splice(userToRemoveIndex, 1);
		}
	}

	const joinFormRequestBroadcastChannel = async (formRequestId: string) => {
		if (authStore.isAuthenticated) {
			formRequestChannel.value = (PusherService.subscribe('presence-form_request.' + formRequestId) as PresenceChannel)
				.bind('pusher:subscription_succeeded', (members: Members) => {
					members.each((member: PresenceChannelUsers) => {
						userJoinedChannel(member.info);
					});
				})
				.bind('pusher:member_added', (member: PresenceChannelUsers) => {
					userJoinedChannel(member.info);
				})
				.bind('pusher:member_removed', (member: PresenceChannelUsers) => {
					userLeftChannel(member.info);
				})
				.bind('formRequest.updated', () => {
					console.log('Received form request push update');
					getFormRequestById(formRequestId);
				});
		} else {
			formRequestChannel.value = PusherService.subscribe('form_request.' + formRequestId).bind('formRequest.updated', () => {
				console.log('Received form request push update');
				getFormRequestById(formRequestId);
			});
		}
	};
	const leaveFormRequestBroadcastChannel = async (previousFormRequestId: string) => {
		if (authStore.isAuthenticated) {
			PusherService.unsubscribe('presence-form_request.' + previousFormRequestId);
		} else {
			PusherService.unsubscribe('form_request.' + previousFormRequestId);
		}
	};

	onMounted(() => {
		getFormRequestById(props.formRequestId);
		joinFormRequestBroadcastChannel(props.formRequestId);
	});

	onBeforeUnmount(() => {
		leaveFormRequestBroadcastChannel(props.formRequestId);
	});

	watch(
		() => props.formRequestId,
		async (newValue, oldValue) => {
			await leaveFormRequestBroadcastChannel(oldValue);
			usersHereNow.value = [];
			loaded.value = false;
			schema.value = undefined;
			formData.value = {} as UnstructuredFormOutput;
			originalFormData.value = undefined;
			await getFormRequestById(newValue);
			await joinFormRequestBroadcastChannel(newValue);
		},
	);

	onBeforeRouteUpdate(async (to, from) => {
		if (to.name == from.name) {
			if (originalFormData.value != JSON.stringify(cleanFormData(formData.value))) {
				const formDataToSave = clone(formData.value);
				const confirmResult = await useDialog.title('Save?').confirm('Do you want to save your changes before you leave!');

				if (confirmResult.isConfirmed) {
					await triggerFormRequestSave(formDataToSave);
				}
			}
		}
	});

	onBeforeRouteLeave(async () => {
		if (originalFormData.value != JSON.stringify(cleanFormData(formData.value))) {
			const confirmResult = await useDialog.title('Whoaaa hold up there!').confirm('Do you really want to leave? you have unsaved changes!');
			// cancel the navigation and stay on the same page
			if (!confirmResult.isConfirmed) {
				return false;
			}
		}
	});
</script>
